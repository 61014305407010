import React, { Fragment, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import TabsetProfile from "./common/detail-order-info";
import TabsetItems from "./common/detail-order-items";
import TabsetComplaint from "./common/detail-order-complaint";
import Breadcrumb from "../common/breadcrumb";
import { Container, Col, Row, Spinner } from "reactstrap";
import { useQuery, gql } from "@apollo/client";
import "./order-detail.css";

const GET_ORDER = gql`
	query getOrder($id: Int!) {
		getOrder(id: $id) {
			id
			order_id
			invoice_no
			customers_id
			customers_name
			store_slug
			store_name
			store_phone
			store_city
			store_province
			name
			phone
			address
			detail_address
			ref_city_id
			ref_city_name
			ref_province_id
			ref_province_name
			ref_district_id
			ref_district_name
			ref_postcode_id
			ref_postcode_name
			postcode
			subtotal
			shipping_vendor
			shipping_product
			shipping_cost
			shipping_cost_
			weight
			discount
			grandtotal
			purchases_date
			created_date
			payment
			payment_status
			status
			no_resi
			status_pengiriman
			service_fee
			shipment_id
			ref_shipping_id
			complaint_date
			complaint_reason
			complaint_approve_date
			complaint_approve_status
			complaint_reject_date
			complaint_reject_reason
			complaint_picture {
				orders_complaint_id
				orders_id
				orders_complaint_picture
			}
			orderList {
				id
				customers_purchases_history_id
				products_id
				products_title
				products_img {
					image_id
					id
					alt
					src
				}
				products_variant_id
				products_variant_title
				products_variant_img {
					image_id
					id
					alt
					src
				}
				qty
				price
				discount
				total
			}
		}
	}
`;

const TokoDetail = () => {
	let { id } = useParams();
	let navigate = useNavigate();
	const user = JSON.parse(localStorage.getItem("user"));
	var { loading, data, refetch } = useQuery(GET_ORDER, {
		variables: {
			id: parseInt(id),
		},
	});
	useEffect(() => {
		if (!user || user.privilege === "Stores") {
			//return navigate("/dashboard", { replace: true });
		}
	}, []);
	useEffect(() => {
		if (!loading && !data.getOrder) {
			return navigate("/404", { replace: true });
		}
	}, [data]);
	return (
		<Fragment>
			{!loading ? (
				<>
					<Breadcrumb title="Detail Order" parent="Orders" />
					<Container fluid={true}>
						<Row>
							<TabsetProfile id={id} data={data} refetch={refetch} />
							<Col xl="8">
								<TabsetItems data={data} />
								{data?.getOrder?.complaint_date ? (
									<TabsetComplaint data={data} user={user} />
								) : (
									""
								)}
							</Col>
						</Row>
					</Container>
				</>
			) : (
				<Container fluid={true}>
					<Row className="justify-content-center pt-4">
						<Spinner />
					</Row>
				</Container>
			)}
		</Fragment>
	);
};

export default TokoDetail;
