import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	Input,
	FormFeedback,
	Button,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { numberFormat } from "../../../helpers/custom";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useQuery, gql, useMutation } from "@apollo/client";

const UPDATE_COMPLAINT = gql`
	mutation updateComplaint($input: UpdateComplaintInput) {
		updateComplaint(input: $input) {
			success
			message
		}
	}
`;

const TabsetProfile = ({ data, user }) => {
	let navigate = useNavigate();
	const [values, setValues] = useState([
		{
			complaint_status: "",
			complaint_approve_status: "",
			complaint_reject_reason: "",
		},
	]);
	const [invalid, setInvalid] = useState([
		{
			complaint_status: "",
			complaint_approve_status: "",
			complaint_reject_reason: "",
		},
	]);
	const [updateTodo] = useMutation(UPDATE_COMPLAINT);
	useEffect(() => {
		if (data && data.getOrder) {
			setValues([
				{
					complaint_status: data.getOrder.complaint_approve_status
						? "accept"
						: "reject",
					complaint_approve_status: data.getOrder.complaint_approve_status,
					complaint_reject_reason: data.getOrder.complaint_reject_reason,
				},
			]);
		}
	}, [data]);

	const handleChange = (event) => {
		let tmp_data = [...values];
		let tmp_invalid_data = [...invalid];
		const { target } = event;
		const value = target.value;
		const { name } = target;
		tmp_invalid_data[0][name] = value ? "" : "invalid";
		tmp_data[0][name] = value;
		setValues(tmp_data);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		const inputData = values;
		let invalidData = [...invalid];
		let valid = 0;
		if (!inputData[0]["complaint_status"]) {
			valid += 1;
			invalidData[0]["complaint_status"] = "invalid";
		} else {
			invalidData[0]["complaint_status"] = "";
			if (inputData[0]["complaint_status"] === "accept") {
				if (!inputData[0]["complaint_approve_status"]) {
					valid += 1;
					invalidData[0]["complaint_approve_status"] = "invalid";
				} else {
					invalidData[0]["complaint_approve_status"] = "";
				}
			} else if (inputData[0]["complaint_status"] === "reject") {
				if (!inputData[0]["complaint_reject_reason"]) {
					valid += 1;
					invalidData[0]["complaint_reject_reason"] = "invalid";
				} else {
					invalidData[0]["complaint_reject_reason"] = "";
				}
			}
		}

		if (valid > 0) {
			setInvalid(invalidData);
			return false;
		}

		inputData[0].id = parseInt(data.getOrder.id);
		inputData[0].uid = parseInt(user.id);

		const result = await updateTodo({ variables: { input: inputData[0] } });
		if (result.data.updateComplaint.success) {
			toast.success(result.data.updateComplaint.message);
			return navigate("/order");
		} else {
			toast.error(result.data.updateComplaint.message);
		}
	};

	return (
		<>
			{data && data.getOrder ? (
				<Card className="" style={{ padding: "30px" }}>
					<div className="border-bottom py-2">
						<h5>Komplain</h5>
					</div>
					<div className="pt-2">
						<form onSubmit={handleSubmit}>
							<div className="w-full d-flex pb-2">
								<div className="col-2">Tanggal</div>
								<div className="col-10">
									{moment(data.getOrder.complaint_date).format("D MMM YYYY")}
								</div>
							</div>

							<div className="w-full d-flex pb-2">
								<div className="col-2">Alasan</div>
								<div className="col-10">{data.getOrder.complaint_reason}</div>
							</div>
							<div className="w-full d-flex pb-2">
								<div className="col-2">Foto</div>
								<div className="col-10 d-flex flex-wrap">
									{data.getOrder.complaint_picture.map((e) => {
										return (
											<Zoom>
												<img
													className="p-2"
													width="100"
													alt=""
													src={
														process.env.REACT_APP_IMAGE_URL +
														e.orders_complaint_picture
													}
												/>
											</Zoom>
										);
									})}
								</div>
							</div>
							<div className="w-full d-flex pb-2">
								<div className="col-2 my-auto">Status</div>
								<div className="col-10">
									<Input
										className="form-control"
										name="complaint_status"
										id="complaint_status"
										type="select"
										value={values[0].complaint_status}
										onChange={handleChange}
										invalid={invalid[0].complaint_status ? "invalid" : ""}
									>
										<option value="">Pilih Salah Satu</option>
										<option value="accept">Diterima</option>
										<option value="reject">Ditolak</option>
									</Input>
									<FormFeedback>This field is required</FormFeedback>
								</div>
							</div>
							{values[0].complaint_status === "accept" ? (
								<div className="w-full d-flex pb-2">
									<div className="col-2">Alasan</div>
									<div className="col-10">
										<Input
											className="form-control"
											name="complaint_approve_status"
											id="complaint_approve_status"
											type="select"
											value={values[0].complaint_approve_status}
											onChange={handleChange}
											invalid={
												invalid[0].complaint_approve_status ? "invalid" : ""
											}
										>
											<option value="">Pilih Salah Satu</option>
											<option value="Refund">Refund</option>
											<option value="Tukar Barang">Tukar Barang</option>
										</Input>
										<FormFeedback>This field is required</FormFeedback>
									</div>
								</div>
							) : (
								""
							)}
							{values[0].complaint_status === "reject" ? (
								<div className="w-full d-flex pb-2">
									<div className="col-2">Alasan Di tolak</div>
									<div className="col-10">
										<Input
											className="form-control mb-0"
											name="complaint_reject_reason"
											id="complaint_reject_reason"
											type="textarea"
											value={values[0].complaint_reject_reason}
											onChange={handleChange}
											invalid={
												invalid[0].complaint_reject_reason ? "invalid" : ""
											}
										/>
										<FormFeedback>This field is required</FormFeedback>
									</div>
								</div>
							) : (
								""
							)}
							{data?.getOrder?.complaint_approve_status ||
							data?.getOrder?.complaint_reject_reason ? (
								""
							) : (
								<div className="">
									<Button type="submit" color="primary">
										Simpan
									</Button>
								</div>
							)}
						</form>
					</div>
				</Card>
			) : (
				""
			)}
		</>
	);
};

export default TabsetProfile;
