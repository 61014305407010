import React, { Fragment, useState, useEffect } from "react";
import {
	Button,
	Form,
	FormGroup,
	Input,
	Label,
	FormFeedback,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { numberFormat } from "../../../helpers/custom";
import moment from "moment";

const GET_STORE = gql`
	query ($slug: String!) {
		store(slug: $slug) {
			id
			name
			slug
			description
			pic
			email
			phone
			address
			ref_cities_list_id
			ref_cities_list_name
			ref_province_list_id
			ref_province_list_name
			ref_district_id
			ref_district_name
			ref_postcode_id
			ref_postcode_name
			image
			created_date
			status
			total_product
			total_transaction_paid
			total_transaction_otw
			total_transaction_finish
			nama_rekening
			no_rekening
			ref_bank_id
			cabang_bank
			geoloc
		}
	}
`;

const GET_SALDO = gql`
	query getSaldoStore($stores_id: Int!) {
		getSaldoStore(stores_id: $stores_id) {
			total
		}
	}
`;

const GET_INFO = gql`
	query getSaldoWithdrawalRequestHistoryStoreDetail($id: Int!) {
		getSaldoWithdrawalRequestHistoryStoreDetail(id: $id) {
			id
			stores_id
			stores_name
			total
			status
			created_date
			created_by
			accepted_date
			accepted_by
			done_date
			done_by
			reject_by
			reject_date
			reject_reason
			sisa_saldo
			nama_bank
			nama_rekening
			no_rekening
		}
	}
`;

const GET_WITHDRAWFEE = gql`
	query getWithdrawFee($date: String!) {
		getWithdrawFee(date: $date) {
			value
		}
	}
`;

const CREATE_SALDO_WITHDRAW = gql`
	mutation createSaldoWithdraw($input: SaldoInput) {
		createSaldoWithdraw(input: $input) {
			success
			message
		}
	}
`;

const UPDATE_STORE = gql`
	mutation updateSaldoWithdraw($input: SaldoInput) {
		updateSaldoWithdraw(input: $input) {
			success
			message
		}
	}
`;

const TabsetUser = ({ id }) => {
	let navigate = useNavigate();
	const user = JSON.parse(localStorage.getItem("user"));
	console.log(user);
	const [hideSave, setHideSave] = useState(false);
	const [values, setValues] = useState([
		{
			id: 0,
			total: "",
			status: "",
			reject_reason: "",
		},
	]);
	const [invalid, setInvalid] = useState([
		{
			total: "",
			status: "",
			reject_reason: "",
		},
	]);
	const [addTodo] = useMutation(CREATE_SALDO_WITHDRAW);
	const [updateTodo] = useMutation(UPDATE_STORE);
	var { data } = useQuery(GET_INFO, {
		variables: {
			id: id > 0 ? parseInt(id) : parseInt(0),
		},
	});
	var { data: dataSaldo, refetch } = useQuery(GET_SALDO, {
		variables: {
			stores_id: user.stores_id ? user.stores_id : 0,
		},
	});

	var { data: dataWithdrawFee, refetch: refetchWithdrawFee } = useQuery(
		GET_WITHDRAWFEE,
		{
			variables: {
				date: data?.getSaldoWithdrawalRequestHistoryStoreDetail
					? data.getSaldoWithdrawalRequestHistoryStoreDetail.created_date
					: moment().format("YYYY/MM/DD"),
			},
		}
	);

	var { data: dataStore } = useQuery(GET_STORE, {
		variables: {
			slug: user?.stores_slug ? user?.stores_slug : "",
		},
	});
	console.log(dataStore);
	useEffect(() => {
		if (data && data.getSaldoWithdrawalRequestHistoryStoreDetail) {
			let tmp_values = [...values];
			tmp_values[0] = {
				id: parseInt(id),
				stores_id: parseInt(
					data.getSaldoWithdrawalRequestHistoryStoreDetail.stores_id
				),
				total: data.getSaldoWithdrawalRequestHistoryStoreDetail.total,
				status: data.getSaldoWithdrawalRequestHistoryStoreDetail.status,
				reject_reason:
					data.getSaldoWithdrawalRequestHistoryStoreDetail.reject_reason,
			};
			refetch({
				stores_id: data.getSaldoWithdrawalRequestHistoryStoreDetail.stores_id,
			});
			setValues(tmp_values);
			if (
				data.getSaldoWithdrawalRequestHistoryStoreDetail.status === "Accept" ||
				data.getSaldoWithdrawalRequestHistoryStoreDetail.status === "Pending"
			) {
				setHideSave(false);
			} else {
				setHideSave(true);
			}
			/*refetchWithdrawFee({
				date: data.getSaldoWithdrawalRequestHistoryStoreDetail.created_date
			})*/
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	useEffect(() => {
		if (dataSaldo) {
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataSaldo]);

	const handleChange = (event) => {
		let tmp_data = [...values];
		const { target } = event;
		const value = target.value;
		const { name } = target;
		tmp_data[0][name] = value;
		setValues(tmp_data);
	};

	// handle submit
	const handleSubmit = async (event) => {
		event.preventDefault();
		const inputData = values;
		let invalidData = [...invalid];
		let valid = 0;
		if (!inputData[0]["total"]) {
			valid += 1;
			invalidData[0]["total"] = "invalid";
		} else {
			invalidData[0]["total"] = "";
		}
		if (valid > 0) {
			let invalidData = [...invalid];
			setInvalid(invalidData);
			return false;
		}
		inputData[0].total = parseInt(inputData[0].total);
		if (inputData[0].id > 0) {
			inputData[0].id = parseInt(inputData[0].id);
			inputData[0].uid = user.id;
			const result = await updateTodo({ variables: { input: inputData[0] } });
			if (result.data.updateSaldoWithdraw.success) {
				toast.success(result.data.updateSaldoWithdraw.message);
				if (user.stores_id) {
					return navigate("../saldo/", { replace: true });
				} else {
					return navigate("../saldo/withdraw/", { replace: true });
				}
			} else {
				toast.error(result.data.updateSaldoWithdraw.message);
			}
		} else {
			delete inputData[0].id;
			inputData[0].stores_id = user.stores_id;
			inputData[0].status = "Pending";
			const result = await addTodo({ variables: { input: inputData[0] } });
			if (result.data.createSaldoWithdraw.success) {
				toast.success(result.data.createSaldoWithdraw.message);
				if (user.stores_id) {
					return navigate("../saldo/", { replace: true });
				} else {
					return navigate("../saldo/withdraw/", { replace: true });
				}
			} else {
				toast.error(result.data.createSaldoWithdraw.message);
			}
		}
	};

	return (
		<Fragment>
			<Form
				className="needs-validation user-add"
				noValidate=""
				onSubmit={handleSubmit}
			>
				{!dataStore?.store?.nama_rekening && user.privilege === "Stores" ? (
					<div class="alert alert-danger" role="alert">
						Nomor Rekening mu kosong. Isi informasi rekening mu{" "}
						<a href="/profile/store/edit" className="alert-link">
							disini
						</a>
					</div>
				) : (
					""
				)}
				<FormGroup className="row">
					<Label className="col-xl-3 col-md-4">Remaining Saldo</Label>
					<div className="col-xl-8 col-md-7">
						<Input
							className="form-control"
							type="text"
							required=""
							disabled="disabled"
							value={
								dataSaldo &&
								numberFormat(parseInt(dataSaldo.getSaldoStore.total))
							}
						/>

						<FormFeedback>This field is required</FormFeedback>
					</div>
				</FormGroup>
				<FormGroup className="row">
					<Label className="col-xl-3 col-md-4">
						<span>*</span> Amount
					</Label>
					<div className="col-xl-8 col-md-7">
						<Input
							className="form-control"
							id="total"
							name="total"
							type="text"
							required=""
							onChange={(e) => handleChange(e)}
							disabled={user.privilege === "Stores" ? "" : "disabled"}
							value={values[0].total}
							invalid={invalid[0].total ? "invalid" : ""}
						/>
						<FormFeedback>This field is required</FormFeedback>
					</div>
				</FormGroup>
				{dataWithdrawFee && dataWithdrawFee.getWithdrawFee ? (
					<FormGroup className="row">
						<Label className="col-xl-3 col-md-4">Withdraw Fee</Label>
						<div className="col-xl-8 col-md-7">
							<Input
								className="form-control"
								id="withdraw_fee"
								name="withdraw_fee"
								type="text"
								required=""
								disabled={"disabled"}
								value={numberFormat(dataWithdrawFee.getWithdrawFee.value)}
							/>
						</div>
					</FormGroup>
				) : (
					""
				)}
				{data && data.getSaldoWithdrawalRequestHistoryStoreDetail ? (
					<FormGroup className="row">
						<Label className="col-xl-3 col-md-4">Bank</Label>
						<div className="col-xl-8 col-md-7">
							<Input
								className="form-control"
								id="total"
								name="total"
								type="text"
								required=""
								disabled={"disabled"}
								value={
									data.getSaldoWithdrawalRequestHistoryStoreDetail.nama_bank
								}
							/>
						</div>
					</FormGroup>
				) : (
					""
				)}
				{data && data.getSaldoWithdrawalRequestHistoryStoreDetail ? (
					<FormGroup className="row">
						<Label className="col-xl-3 col-md-4">Nama Rekening</Label>
						<div className="col-xl-8 col-md-7">
							<Input
								className="form-control"
								id="total"
								name="total"
								type="text"
								required=""
								disabled={"disabled"}
								value={
									data &&
									data.getSaldoWithdrawalRequestHistoryStoreDetail.nama_rekening
								}
							/>
						</div>
					</FormGroup>
				) : (
					""
				)}
				{data && data.getSaldoWithdrawalRequestHistoryStoreDetail ? (
					<FormGroup className="row">
						<Label className="col-xl-3 col-md-4">No Rekening</Label>
						<div className="col-xl-8 col-md-7">
							<Input
								className="form-control"
								id="total"
								name="total"
								type="text"
								required=""
								disabled={"disabled"}
								value={
									data &&
									data.getSaldoWithdrawalRequestHistoryStoreDetail.no_rekening
								}
							/>
						</div>
					</FormGroup>
				) : (
					""
				)}
				{user.privilege === "Stores" ? (
					""
				) : (
					<>
						<FormGroup className="row">
							<Label className="col-xl-3 col-md-4">
								<span>*</span> Status
							</Label>
							<div className="col-xl-8 col-md-7">
								<Input
									className="form-control"
									id="status"
									name="status"
									type="select"
									required=""
									onChange={(e) => handleChange(e)}
									value={values[0].status}
									invalid={invalid[0].status ? "invalid" : ""}
								>
									<option value="Pending">Pending</option>
									<option value="Accept">Accept</option>
									<option value="Done">Done</option>
									<option value="Reject">Reject</option>
								</Input>

								<FormFeedback>This field is required</FormFeedback>
							</div>
						</FormGroup>
						{/* <FormGroup className="row">
							<Label className="col-xl-3 col-md-4">
								<span>*</span> Bukti Pembayaran
							</Label>
							<div className="col-xl-8 col-md-7">
								<Input
									className="form-control"
									id="transfer_evidence"
									name="transfer_evidence"
									type="file"
									required=""
								/>
							</div>
						</FormGroup> */}
						{data &&
						data.getSaldoWithdrawalRequestHistoryStoreDetail &&
						data.getSaldoWithdrawalRequestHistoryStoreDetail.status ===
							"Accept" ? (
							<>
								<FormGroup className="row">
									<Label className="col-xl-3 col-md-4">Accept Date</Label>
									<div className="col-xl-8 col-md-7">
										<Input
											className="form-control"
											id="reject_reason"
											name="reject_reason"
											type="text"
											disabled
											required=""
											value={
												data.getSaldoWithdrawalRequestHistoryStoreDetail
													.accepted_date
											}
										/>
									</div>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-xl-3 col-md-4">Accept By</Label>
									<div className="col-xl-8 col-md-7">
										<Input
											className="form-control"
											id="reject_reason"
											name="reject_reason"
											type="text"
											disabled
											required=""
											value={
												data.getSaldoWithdrawalRequestHistoryStoreDetail
													.accepted_by
											}
										/>
									</div>
								</FormGroup>
							</>
						) : (
							""
						)}
						{data &&
						data.getSaldoWithdrawalRequestHistoryStoreDetail &&
						data.getSaldoWithdrawalRequestHistoryStoreDetail.status ===
							"Reject" ? (
							<>
								<FormGroup className="row">
									<Label className="col-xl-3 col-md-4">Reject Date</Label>
									<div className="col-xl-8 col-md-7">
										<Input
											className="form-control"
											id="reject_reason"
											name="reject_reason"
											type="text"
											disabled
											required=""
											value={
												data.getSaldoWithdrawalRequestHistoryStoreDetail
													.reject_date
											}
										/>
									</div>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-xl-3 col-md-4">Reject By</Label>
									<div className="col-xl-8 col-md-7">
										<Input
											className="form-control"
											id="reject_reason"
											name="reject_reason"
											type="text"
											disabled
											required=""
											value={
												data.getSaldoWithdrawalRequestHistoryStoreDetail
													.reject_by
											}
										/>
									</div>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-xl-3 col-md-4">Reject Reason</Label>
									<div className="col-xl-8 col-md-7">
										<Input
											className="form-control"
											id="reject_reason"
											name="reject_reason"
											type="text"
											disabled
											required=""
											value={
												data.getSaldoWithdrawalRequestHistoryStoreDetail
													.reject_reason
											}
										/>
									</div>
								</FormGroup>
							</>
						) : (
							""
						)}
						{data &&
						data.getSaldoWithdrawalRequestHistoryStoreDetail &&
						data.getSaldoWithdrawalRequestHistoryStoreDetail.status ===
							"Done" ? (
							<>
								<FormGroup className="row">
									<Label className="col-xl-3 col-md-4">Done Date</Label>
									<div className="col-xl-8 col-md-7">
										<Input
											className="form-control"
											id="reject_reason"
											name="reject_reason"
											type="text"
											disabled
											required=""
											value={
												data.getSaldoWithdrawalRequestHistoryStoreDetail
													.done_date
											}
										/>
									</div>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-xl-3 col-md-4">Done By</Label>
									<div className="col-xl-8 col-md-7">
										<Input
											className="form-control"
											id="reject_reason"
											name="reject_reason"
											type="text"
											disabled
											required=""
											value={
												data.getSaldoWithdrawalRequestHistoryStoreDetail.done_by
											}
										/>
									</div>
								</FormGroup>
							</>
						) : (
							""
						)}
						{values[0].status === "reject" ? (
							<FormGroup className="row">
								<Label className="col-xl-3 col-md-4">
									<span>*</span> Reject Reason
								</Label>
								<div className="col-xl-8 col-md-7">
									<Input
										className="form-control"
										id="reject_reason"
										name="reject_reason"
										type="textarea"
										required=""
										onChange={(e) => handleChange(e)}
										value={values[0].reject_reason}
										invalid={invalid[0].reject_reason ? "invalid" : ""}
									/>
									<FormFeedback>This field is required</FormFeedback>
								</div>
							</FormGroup>
						) : (
							""
						)}
					</>
				)}

				{!hideSave ? (
					<FormGroup className="row">
						<div className="pull-right">
							<Button type="submit" color="primary">
								Save
							</Button>
						</div>
					</FormGroup>
				) : (
					""
				)}
			</Form>
		</Fragment>
	);
};

export default TabsetUser;
