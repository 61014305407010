import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import Filter from "../../common/filter_components/filter";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import ModalDetailSaldoWithdrawRequest from "./modal-detail-saldo-withdraw-request";
import { numberFormat } from "../../../helpers/custom";

const GET_SALDO_WITHDRAWAL_HISTORY = gql`
	query (
		$indexFrom: Int
		$limit: Int
		$status: String
		$start_date: String
		$end_date: String
		$sortBy: String
		$payment_id: String
		$orders_no: String
	) {
		getRefundList(
			indexFrom: $indexFrom
			limit: $limit
			status: $status
			start_date: $start_date
			end_date: $end_date
			sortBy: $sortBy
			payment_id: $payment_id
			orders_no: $orders_no
		) {
			total
			hasMore
			items {
				id
				orders_id
				payment_id
				orders_no
				nama
				no_rek
				nama_bank
				status
				accept_date
				accept_by
				accept_by_name
				reject_date
				reject_by
				reject_by_name
				reject_reason
				done_date
				done_by
				done_by_name
				created_date
				total
				customer_name
				store_name
			}
		}
	}
`;

const TabsetProfile = ({ stores_id }) => {
	const sizePerPage = 10;
	const [detailData, setDetailData] = useState([]);
	const [openModalDetail, setOpenModalDetail] = useState(false);
	const [page, setPage] = useState(1);
	const [filter, setFilter] = useState({
		date: "",
		total: "",
		status: "",
	});
	const [sortBy, setSortBy] = useState();
	const [defaultSorted, setDefaultSorted] = useState([
		{
			dataField: "id",
			order: "desc",
		},
	]);
	var { data, refetch } = useQuery(GET_SALDO_WITHDRAWAL_HISTORY, {
		variables: {
			indexFrom: page * sizePerPage - sizePerPage,
			limit: sizePerPage,
			status: filter && filter.status ? filter.status.value : "",
			start_date: filter && filter.date ? filter.date.start : "",
			end_date: filter && filter.date ? filter.date.end : "",
			sortBy: sortBy,
			payment_id: filter && filter.payment_id ? filter.payment_id : "",
			orders_no: filter && filter.orders_no ? filter.orders_no : "",
		},
	});

	const columns = [
		{
			dataField: "id",
			text: "ID",
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: "10%" };
			},
		},
		{
			dataField: "payment_id",
			text: "Payment ID",
			sort: true,
		},
		{
			dataField: "orders_no",
			text: "Order ID",
			sort: true,
		},
		/*{
			dataField: 'id',
			text: 'Order ID',
			sort: true,
			formatter: (cell, row, rowIndex, extraData) => (
				moment(row.created_date).format('YYYYMD')+row.id
			),
		},*/
		{
			dataField: "customer_name",
			text: "Customer",
			sort: true,
		},
		{
			dataField: "store_name",
			text: "Store",
			sort: true,
		},
		{
			dataField: "total",
			text: "Total",
			sort: true,
			formatter: (cell, row, rowIndex, extraData) =>
				numberFormat(parseInt(row.total)),
		},
		{
			dataField: "created_date",
			text: "Date",
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: "15%" };
			},
		},
		{
			dataField: "status",
			text: "Status",
			sort: true,
		},
		{
			dataField: "",
			text: "",
			formatter: (cell, row, rowIndex, extraData) => (
				<div className="openbtn text-center">
					<Link onClick={() => showModalDetail(row)}>
						<i className="fa fa-edit" />
					</Link>
				</div>
			),
			headerStyle: (colum, colIndex) => {
				return { width: "5%" };
			},
		},
	];
	const showModalDetail = (data) => {
		setDetailData(data);
		setOpenModalDetail(true);
	};

	const filterOptions = [
		{
			id: "date",
			label: "Date",
			value: filter.date,
			type: "date",
			className: "first-column",
		},
		{
			id: "payment_id",
			label: "Payment ID",
			value: filter.payment_id,
			type: "text",
		},
		{
			id: "orders_no",
			label: "Order ID",
			value: filter.orders_no,
			type: "text",
		},
		{
			id: "status",
			label: "Status",
			value: filter.status,
			type: "dropdown",
			options: [
				{
					label: "All",
					value: "",
				},
				{
					label: "Pending",
					value: "Pending",
				},
				{
					label: "Done",
					value: "Done",
				},
			],
		},
	];

	const handleTableChange = (
		e,
		{ filters, page, sizePerPage, sortField, sortOrder, data }
	) => {
		if (page) {
			setPage(page);
		}
		if (sortField && sortOrder) {
			setSortBy(sortField + "-" + sortOrder);
			setDefaultSorted([
				{
					dataField: sortField,
					order: sortOrder,
				},
			]);
		}
	};
	const handleFilterChange = (e) => {
		refetch({
			stores_id: parseInt(stores_id),
			indexFrom: page * sizePerPage - sizePerPage,
			limit: sizePerPage,
			status: filter && filter.status ? filter.status.value : "",
			start_date: filter && filter.date ? filter.date.start : "",
			end_date: filter && filter.date ? filter.date.end : "",
			sortBy: sortBy,
			payment_id: filter && filter.payment_id ? filter.payment_id : "",
			orders_no: filter && filter.orders_no ? filter.orders_no : "",
		});
		setFilter(e);
	};

	return (
		<>
			<h5 className="f-w-600 f-16 pull-left">Refund History</h5>
			<div className="clearfix"></div>
			<Filter
				filterOptions={filterOptions}
				filter={filter}
				handleFilterParentChange={handleFilterChange}
			/>
			<div
				id="batchDelete"
				className="category-table store-list order-table coupon-list-delete"
			>
				{data ? (
					<>
						<BootstrapTable
							keyField="id"
							hover
							condensed
							remote={{ sort: true, pagination: true }}
							data={data && data.getRefundList ? data.getRefundList.items : []}
							columns={columns}
							bordered={false}
							noDataIndication="Table is Empty"
							pagination={paginationFactory({
								sizePerPage: sizePerPage,
								page: page,
								hideSizePerPage: true,
								hidePageListOnlyOnePage: true,
								totalSize:
									data && data.getRefundList ? data.getRefundList.total : 0,
							})}
							defaultSorted={defaultSorted}
							onTableChange={handleTableChange}
							wrapperClasses="table-responsive"
						/>
						<ModalDetailSaldoWithdrawRequest
							data={detailData}
							openModalDetail={openModalDetail}
							setOpenModalDetail={setOpenModalDetail}
							refetch={refetch}
						/>
					</>
				) : (
					""
				)}
			</div>
		</>
	);
};

export default TabsetProfile;
