import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Link } from "react-router-dom";
import { Container, Row, Card, CardBody } from "reactstrap";
import { useQuery, gql } from "@apollo/client";
import {
	numberFormat,
	orderStatus,
	orderStatusOptions,
} from "../../helpers/custom";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import Filter from "../common/filter_components/filter";
import moment from "moment";

const GET_ORDERS = gql`
	query getOrders(
		$custName: String
		$indexFrom: Int
		$limit: Int
		$cid: Int
		$status: String
		$store_name: String
		$city: String
		$sortBy: String
		$total: String
		$order_id: String
		$invoice_no: String
		$start_date: String
		$end_date: String
	) {
		getOrders(
			custName: $custName
			indexFrom: $indexFrom
			limit: $limit
			sortBy: $sortBy
			cid: $cid
			status: $status
			city: $city
			total: $total
			store_name: $store_name
			order_id: $order_id
			invoice_no: $invoice_no
			start_date: $start_date
			end_date: $end_date
		) {
			total
			hasMore
			grossProfit
			serviceFee
			netProfit
			items {
				id
				order_id
				customers_id
				customers_name
				store_slug
				store_name
				name
				phone
				address
				detail_address
				ref_city_id
				ref_city_name
				ref_province_id
				ref_province_name
				postcode
				subtotal
				shipping_cost
				shipping_cost_
				discount
				grandtotal
				purchases_date
				created_date
				payment
				status
				invoice_no
				complaint_date
				orderList {
					id
					customers_purchases_history_id
					products_id
					products_title
					products_img {
						image_id
						id
						alt
						src
					}
					products_variant_id
					products_variant_title
					products_variant_img {
						image_id
						id
						alt
						src
					}
					qty
					price
					discount
					total
				}
			}
		}
	}
`;

const columns = [
	{
		dataField: "id",
		text: "#",
		sort: true,
		headerStyle: (colum, colIndex) => {
			return { width: "5%" };
		},
	},
	{
		dataField: "invoice_no",
		text: "Payment ID",
		sort: true,
	},
	{
		dataField: "order_id",
		text: "Order ID",
		sort: true,
	},
	{
		dataField: "name",
		text: "Nama",
		sort: true,
	},
	{
		dataField: "store_name",
		text: "Toko",
		sort: true,
	},
	{
		dataField: "subtotal",
		text: "Total",
		sort: true,
		formatter: (cell, row, rowIndex, extraData) =>
			numberFormat(parseInt(row.subtotal)),
		headerStyle: (colum, colIndex) => {
			return { width: "12%" };
		},
	},
	{
		dataField: "created_date",
		text: "Tanggal Order",
		sort: true,
		formatter: (cell, row, rowIndex, extraData) =>
			moment(row.created_date).format("D MMM YY"),
	},
	{
		dataField: "status",
		text: "Status",
		formatter: (cell, row, rowIndex, extraData) =>
			extraData[cell] + (row.complaint_date ? " - Komplain" : ""),
		formatExtraData: orderStatus,
		sort: true,
		headerStyle: (colum, colIndex) => {
			return { width: "12%" };
		},
	},
	{
		dataField: "",
		text: "",
		formatter: (cell, row, rowIndex, extraData) => (
			<div className="openbtn text-center">
				<Link to={`/order/${row.id}`}>
					<i className="fa fa-eye" title="Lihat" />
				</Link>
			</div>
		),
		headerStyle: (colum, colIndex) => {
			return { width: "5%" };
		},
	},
];

const columns_store = [
	{
		dataField: "order_id",
		text: "Order ID",
		sort: true,
		headerStyle: (colum, colIndex) => {
			return { width: "10%" };
		},
	},
	{
		dataField: "invoice_no",
		text: "Payment ID",
		sort: true,
	},
	{
		dataField: "name",
		text: "Nama",
		sort: true,
	},
	{
		dataField: "subtotal",
		text: "Total",
		sort: true,
		formatter: (cell, row, rowIndex, extraData) =>
			numberFormat(parseInt(row.subtotal)),
		headerStyle: (colum, colIndex) => {
			return { width: "12%" };
		},
	},
	{
		dataField: "created_date",
		text: "Tanggal Order",
		sort: true,
	},
	{
		dataField: "status",
		text: "Status",
		formatter: (cell, row, rowIndex, extraData) => extraData[cell],
		formatExtraData: orderStatus,
		sort: true,
		headerStyle: (colum, colIndex) => {
			return { width: "12%" };
		},
	},
	{
		dataField: "",
		text: "",
		formatter: (cell, row, rowIndex, extraData) => (
			<div className="openbtn text-center">
				<Link to={`/order/${row.id}`}>
					<i className="fa fa-eye" title="Lihat" />
				</Link>
			</div>
		),
		headerStyle: (colum, colIndex) => {
			return { width: "5%" };
		},
	},
];

const columns_mobile = [
	{
		dataField: "id",
		text: "#",
		sort: true,
		headerStyle: (colum, colIndex) => {
			return { width: "13%" };
		},
	},
	{
		dataField: "name",
		text: "Nama",
		sort: true,
		formatter: (cell, row, rowIndex, extraData) => (
			<>
				<div>
					<b>{row.name}</b>
				</div>
				<div>{numberFormat(parseInt(row.subtotal))}</div>
			</>
		),
	},
	{
		dataField: "created_date",
		text: "Tanggal Order",
		sort: true,
	},
	{
		dataField: "status",
		text: "Status",
		formatter: (cell, row, rowIndex, extraData) => extraData[cell],
		formatExtraData: orderStatus,
		sort: true,
	},
	{
		dataField: "",
		text: "",
		formatter: (cell, row, rowIndex, extraData) => (
			<div className="openbtn text-center">
				<Link to={`/order/${row.id}`}>
					<i className="fa fa-eye" title="Lihat" />
				</Link>
			</div>
		),
		headerStyle: (colum, colIndex) => {
			return { width: "5%" };
		},
	},
];

const Product_list = () => {
	const is_mobile = window.innerWidth < 750 ? true : false;
	const user = JSON.parse(localStorage.getItem("user"));
	const [page, setPage] = useState(1);
	const sizePerPage = 10;
	const [filter, setFilter] = useState({
		order_id: "",
		custName: "",
		store_name: "",
		total: "",
		date: { start: "", end: "" },
		status: "",
	});

	const [sortBy, setSortBy] = useState();

	const [defaultSorted, setDefaultSorted] = useState([
		{
			dataField: "created_date",
			order: "desc",
		},
	]);

	var { data, refetch } = useQuery(GET_ORDERS, {
		variables: {
			indexFrom: page * sizePerPage - sizePerPage,
			limit: sizePerPage,
			order_id: filter && filter.order_id ? filter.order_id : "",
			invoice_no: filter && filter.invoice_no ? filter.invoice_no : "",
			custName: filter && filter.custName ? filter.custName : "",
			store_name:
				filter && filter.store_name
					? filter.store_name
					: user.stores_name
					? user.stores_name
					: "",
			total: filter && filter.total ? filter.total : "",
			status: filter && filter.status ? filter.status.value : "",
			start_date: filter && filter.date ? filter.date.start : "",
			end_date: filter && filter.date ? filter.date.end : "",
			sortBy: sortBy,
		},
	});

	const handleTableChange = async (
		e,
		{ filters, page: pages, sizePerPage, sortField, sortOrder, data }
	) => {
		if (pages) {
			setPage(pages);
		}
		if (sortField && sortOrder) {
			setSortBy(sortField + "-" + sortOrder);
			setDefaultSorted([
				{
					dataField: sortField,
					order: sortOrder,
				},
			]);
		}
	};

	const handleFilterChange = (e) => {
		refetch({
			indexFrom: page * sizePerPage - sizePerPage,
			limit: sizePerPage,
			order_id: filter && filter.order_id ? filter.order_id : "",
			invoice_no: filter && filter.invoice_no ? filter.invoice_no : "",
			custName: filter && filter.custName ? filter.custName : "",
			store_name:
				filter && filter.store_name
					? filter.store_name
					: user.stores_name
					? user.stores_name
					: "",
			total: filter && filter.total ? filter.total : "",
			status: filter && filter.status ? filter.status.value : "",
			start_date: filter && filter.date ? filter.date.start : "",
			end_date: filter && filter.date ? filter.date.end : "",
			sortBy: sortBy,
		});
		setFilter(e);
	};

	const filterOptions = [
		{
			id: "order_id",
			label: "Payment ID",
			value: filter.order_id,
			type: "text",
		},
		{
			id: "invoice_no",
			label: "Order ID",
			value: filter.invoice_no,
			type: "text",
		},
		{
			id: "custName",
			label: "Nama",
			value: filter.custName,
			type: "text",
		},
		{
			id: "store_name",
			label: "Toko",
			value: filter.store_name,
			type: "text",
		},
		/*{
			id: 'total',
			label: 'Total',
			value: filter.total,
			type: 'text',
		},*/
		{
			id: "date",
			label: "Date",
			value: filter.date,
			type: "date",
			className: "first-column",
		},
		{
			id: "status",
			label: "Status",
			value: filter.status,
			type: "dropdown",
			options: orderStatusOptions,
		},
	];

	return (
		<Fragment>
			<Breadcrumb title="Orders" />
			<Container fluid={true}>
				<Card>
					<CardBody>
						<div className="col-md-4" style={{ marginBottom: "1rem" }}>
							<div style={{ display: "flex" }}>
								<div
									className="col-5"
									style={{ display: "flex", justifyContent: "space-between" }}
								>
									<span>Pendapatan Kotor</span>
									<span>:</span>
								</div>
								<div className="col" style={{ textAlign: "right" }}>
									{data?.getOrders.grossProfit
										? numberFormat(parseInt(data.getOrders.grossProfit))
										: 0}
								</div>
							</div>
							<div style={{ display: "flex" }}>
								<div
									className="col-5"
									style={{ display: "flex", justifyContent: "space-between" }}
								>
									<span>Biaya Layanan Toko</span>
									<span>:</span>
								</div>
								<div className="col" style={{ textAlign: "right" }}>
									{data?.getOrders.grossProfit
										? numberFormat(parseInt(data.getOrders.serviceFee))
										: 0}
								</div>
							</div>
							<div style={{ display: "flex" }}>
								<div
									className="col-5"
									style={{ display: "flex", justifyContent: "space-between" }}
								>
									<span>Pendapatan Bersih</span>
									<span>:</span>
								</div>
								<div className="col" style={{ textAlign: "right" }}>
									{data?.getOrders.grossProfit
										? numberFormat(parseInt(data.getOrders.netProfit))
										: 0}
								</div>
							</div>
							<div style={{ fontSize: "10px", color: "grey" }}>
								*) sesuai dengan filter
							</div>
						</div>
						<Filter
							filterOptions={filterOptions}
							filter={filter}
							handleFilterParentChange={handleFilterChange}
						/>
						<Row className="products-admin ratio_asos">
							{data && data.getOrders ? (
								<BootstrapTable
									keyField="id"
									hover
									condensed
									remote={{ sort: true, pagination: true }}
									data={data && data.getOrders ? data.getOrders.items : []}
									columns={
										is_mobile
											? columns_mobile
											: user.stores_id
											? columns_store
											: columns
									}
									bordered={false}
									noDataIndication="Table is Empty"
									pagination={paginationFactory({
										sizePerPage: sizePerPage,
										page: page,
										hideSizePerPage: true,
										hidePageListOnlyOnePage: true,
										totalSize:
											data && data.getOrders ? data.getOrders.total : 0,
									})}
									defaultSorted={defaultSorted}
									wrapperClasses="table-responsive"
									onTableChange={handleTableChange}
								/>
							) : (
								""
							)}
						</Row>
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};

export default Product_list;
