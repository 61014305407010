import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.scss";
import Routers from "./routes";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
const defaultOptions = {
	watchQuery: {
		fetchPolicy: "no-cache",
		errorPolicy: "ignore",
	},
	query: {
		fetchPolicy: "no-cache",
		errorPolicy: "all",
	},
};
const client = new ApolloClient({
	uri: 'https://bisabelanjabe.hmdigital.us/api/v1',
	cache: new InMemoryCache(),
	link: createUploadLink({ uri: "https://bisabelanjabe.hmdigital.us/api/v1" }),
	defaultOptions: defaultOptions,
});
const Root = () => {
	return (
		<ApolloProvider client={client}>
			<React.StrictMode>
				<BrowserRouter basename={"/"}>
					<PerfectScrollbar>
						<Routers />
					</PerfectScrollbar>
				</BrowserRouter>
			</React.StrictMode>
		</ApolloProvider>
	);
};

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
	<React.StrictMode>
		<Root />
	</React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
